<template>
  <Modal
    :title="'Change Date Request'"
    :show="show"
    :class="'modal-log-activity-management'"
    :isSubmit="false"
    :isCancel="false"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <div class="columns is-multiline has-text-color-label">
        <div class="column is-9 is-flex is-align-items-center">
          <img
            class="selected-time-off-approval-profile-picture"
            :src="
              determineProfilePicture(
                selectedChangeSchedule.requestedUser.profilePictureUrl
              )
            "
          />
          <div>
            <span
              class="time-off-approval-modal-content-full-name has-text-weight-semibold"
            >
              {{ selectedChangeSchedule.requestedUser.fullName }}
            </span>
            <br />
            <span class="time-off-approval-modal-content-position-name">
              {{ selectedChangeSchedule.requestedUser.position }}
            </span>
          </div>
        </div>
        <div
          class="column is-3 is-flex is-align-items-center is-justify-content-flex-end"
        >
          <div
            :class="`time-off-approval-modal-status-container has-text-white has-text-weight-semibold ${determineBGClassByStatus(
              selectedChangeSchedule.status
            )}`"
          >
            {{ selectedChangeSchedule.status }}
          </div>
        </div>

        <div class="column is-5">
          <p class="has-text-weight-bold">
            Actual Date
            <br />
            <span class="has-text-weight-normal">
              {{ formatDate(selectedChangeSchedule.date) }}
            </span>
          </p>
        </div>

        <div class="column is-7">
          <p class="has-text-weight-bold">
            Date Changes To
            <br />
            <span class="has-text-weight-normal">
              {{ formatDate(selectedChangeSchedule.changeDate) }}
            </span>
          </p>
        </div>

        <div class="column is-12">
          <p class="has-text-weight-bold">
            Request Reason
            <br />
            <span class="has-text-weight-normal">
              {{
                selectedChangeSchedule.requestReason
                  ? selectedChangeSchedule.requestReason
                  : '-'
              }}
            </span>
          </p>
        </div>
        <div class="column is-12">
          <p class="has-text-weight-bold">
            Approved By
            <br />
            <TimeOffApprovalHistoryComponent
              class="has-text-color-label"
              :approval-history="selectedChangeSchedule.history"
            />
          </p>
        </div>

        <div
          class="modal-approval-button-container"
          v-if="selectedChangeSchedule.status === 'waiting'"
        >
          <b-button
            outlined
            type="is-primary"
            class="has-text-weight-bold has-text-black"
            @click="
              updateStatusApproval({
                id: selectedChangeSchedule.id,
                status: 'declined',
              })
            "
            :disabled="disableDeclined || isUpdatingDeclined"
          >
            {{ isUpdatingDeclined ? 'Declining...' : 'Decline' }}
          </b-button>
          <b-button
            type="is-primary"
            expanded
            class="has-text-weight-bold"
            @click="
              updateStatusApproval({
                id: selectedChangeSchedule.id,
                status: 'approved',
              })
            "
            :disabled="disableAccepted || isUpdatingAccepted"
          >
            {{ isUpdatingAccepted ? 'Approving...' : 'Approve' }}
          </b-button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
//component
import moment from 'moment'
import { determineBackgroundColorByStatus } from '@/services/util'
import Modal from '../../../components/Modals/Modal.vue'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'

export default {
  components: { Modal, TimeOffApprovalHistoryComponent },

  data() {
    return {}
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedChangeSchedule: {
      type: Object,
      default: null,
    },
    userApproval: {
      type: Array,
      default: null,
    },
    disableDeclined: {
      type: Boolean,
      default: false,
    },
    disableAccepted: {
      type: Boolean,
      default: false,
    },
    disableCanceled: {
      type: Boolean,
      default: false,
    },
    isUpdatingAccepted: {
      type: Boolean,
      default: false,
    },
    isUpdatingDeclined: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    /**
     * Emit hide modal component.
     */
    hide() {
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },

    /**
     * Emit complete approval.
     */
    completeApproval(event) {
      this.$emit('completeApproval', event)
    },

    /**
     * Emit Update Status Approval.
     */
    updateStatusApproval({ id, status }) {
      const param = {
        id,
        status,
      }
      this.$emit('updateStatusApproval', param)
    },

    /**
     * Determine background color by status
     * @param {String} status - status
     * @return {String} background color css class
     */
    determineBGClassByStatus(status) {
      return determineBackgroundColorByStatus(status)
    },

    /**
     * Set Format Date
     * @param {Date} str - date string
     * @return {Date} formatted date
     */
    formatDate(str) {
      return moment(str).format('DD MMMM YYYY')
    },

    /**
     * Set Session format
     * @param {integer} data - ID session
     */
    formatSession(number) {
      switch (number) {
        case 1:
          return 'Morning'

        case 2:
          return 'Afternoon'

        default:
          return 'Full Day'
      }
    },

    /**
     * Set WFO Session css class
     * @param {integer} data - id session
     * @return {String} session class name
     */
    formatSessionClass(number) {
      switch (number) {
        case 1:
          return 'session-morning'

        case 2:
          return 'session-afternoon'

        default:
          return 'session-full-day'
      }
    },

    /**
     * Determine Profile picture from database
     * @param {String} picture - Picture URL
     * @param {String} gender - gender string
     * used if no picture is present ('F'/ 'M')
     * @return {String} default gender picture url
     */
    determineProfilePicture(picture, gender) {
      if (!picture) {
        if (gender === 'F') {
          // female
          return '/images/default-profile-picture-female.png'
        }
        // male
        return '/images/default-profile-picture-male.png'
      }

      return picture
    },

    /**
     * Get Reimbursement Image URL
     * @param {Object} item - Image object from database
     * @return {String} Image URL
     */
    getImageURL(item) {
      if (item?.[0]?.url) {
        return process.env.VUE_APP_API_URL + item[0].url
      }
      return false
    },
  },
}
</script>
